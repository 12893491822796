import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './core/public-layout/public-layout.component';
import { HomePageComponent } from './core/public-layout/pages/home-page/home-page.component';
import { JobsPageComponent } from './core/public-layout/pages/jobs-page/jobs-page.component';
import { JobDetailsPageComponent } from './core/public-layout/pages/job-details-page/job-details-page.component';
import { AboutUsPageComponent } from './core/public-layout/pages/about-us-page/about-us-page.component';
import { ContactPageComponent } from './core/public-layout/pages/contact-page/contact-page.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './core/public-layout/pages/privacy-policy/privacy-policy.component';
import { FamilyPageComponent } from './core/public-layout/pages/family-page/family-page.component';
import { NewsPageComponent } from './core/public-layout/pages/news-page/news-page.component';
import { NewsDetailsPageComponent } from './core/public-layout/pages/news-details-page/news-details-page.component';
import { EmployerPageComponent } from './core/public-layout/pages/employer-page/employer-page.component';
import { ApplySuccessComponent } from './core/components/apply-components/apply-success/apply-success.component';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', component: HomePageComponent },
      {
        path: 'jobs',
        component: JobsPageComponent,
      },
      {
        path: 'job/:jobId',
        component: JobDetailsPageComponent,
      },
      // TODO add route guard?
      {
        path: 'success',
        component: ApplySuccessComponent,
      },
      {
        path: 'contact',
        component: ContactPageComponent,
      },
      {
        path: 'about',
        component: AboutUsPageComponent,
      },
      {
        path: 'family',
        component: FamilyPageComponent,
      },
      {
        path: 'news',
        component: NewsPageComponent,
      },
      {
        path: 'employer',
        component: EmployerPageComponent,
      },
      {
        path: 'news/:newsId',
        component: NewsDetailsPageComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
