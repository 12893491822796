import { ITeamMember } from '../interfaces/team-member';

export const allTeamMembers: ITeamMember[] = [
  {
    name: 'Maarten Kleve',
    email: 'm.kleve@motuspeople.com',
    image: 'assets/images/maarten-kleve.png',
    description:
      "I'll do my utmost to further develop people from all over the world in their personal and professional careers! Connect them with the most advanced tech companies in the region and provide full relocation support.\n" +
      '\n' +
      'My job is simple; I want to work with great people.',
    isActive: true,
    role: 'Managing Partner',
    linkedInUrl: 'https://www.linkedin.com/in/maarten-k-4a185430/',
    phone: '+31 (0)6 23622416',
  },
  {
    name: 'René Hooijmans',
    email: 'r.hooijmans@motuspeople.com',
    image: 'assets/images/rene-hooijmans.png',
    description:
      'Impacting careers and touching lives. That’s what drives me to give the very best of myself every single day.\n' +
      '\n' +
      'With my background in Human Resources, I would say I’m truly passionate about people development and career progress. I’m not the typical recruiter. For me, it’s all about enabling talents in identifying their perfect next step by finding a company and position in which they will be challenged and learn, thereby allowing them to take their development and careers to the next level. For me, knowing that I work with talented people who entrust me to change their careers and lives fills me with pride every day.',
    isActive: true,
    role: 'Managing Partner',
    linkedInUrl: 'https://www.linkedin.com/in/renehooijmans/',
    phone: '+31 (0)6 44551915',
  },
  {
    name: 'Stephan Smulders',
    email: 's.smulders@motuspeople.com',
    image: 'assets/images/stephan-smulders.png',
    description:
      'My entire career has been focussed on international recruitment for high-tech companies active in the brainport region. I consider myself an expert in supporting talents in their relocation to the Netherlands, where I give the best of myself in supporting my candidates in every aspect of the process. With my personal touch and genuine care for people, I try to touch people’s lives day by day. Leading the Operations business unit of Motus People, my speciality lies in recruitment for all Technician functions across all technical domains. Interested to know more? It would be my pleasure to connect in order to discuss our openings and to explain how I can help to change your life as from tomorrow.',
    isActive: true,
    role: 'Managing Partner',
    linkedInUrl: 'https://www.linkedin.com/in/stephansmulders/',
    phone: '+31 (0)6 21434481',
  },
  {
    name: 'Natasha Slabbert',
    email: 's.slabbert@motuspeople.com',
    image: 'assets/images/natasha-slabbert.png',
    description:
      'My first job involved teaching and looking after little humans. (First Grade Teacher) In April 2023, my husband and I relocated to the Netherlands, from South Africa. In addition to this exciting new step, I also joined the Motus People family, where I am currently blooming.\n' +
      '\n' +
      'At the end of the day, I have always had a heart for people and as a Recruitment Consultant, it is my privilege to be able to help my candidates make the transition a smooth and pleasant experience.\n' +
      '\n' +
      'Beyond my profession, you’ll also find a person who enjoys DIY’s, good food, dancing and loves her dog unconditionally. \n' +
      '\n' +
      '“The only way to make sense out of change, is to plunge into it, move with it, and join the dance.” – Alan W. Watts',
    isActive: true,
    role: 'Recruitment Consultant',
    linkedInUrl:
      'https://www.linkedin.com/in/natasha-slabbert-helpingpeoplegrow/',
    phone: '+31 (0)6 8127 6670',
  },
  {
    name: 'Stijn Leijte',
    email: 's.leijte@motuspeople.com',
    image: 'assets/images/stijn-leijte.png',
    description:
      'As a previous technician myself, I believe I can really add value to the lives and careers of both employee and employer in the high-tech industry.\n' +
      '\n' +
      'I am always up for a chat to discuss your career prospects. Let’s build your future together!',
    isActive: true,
    role: 'Recruitment Consultant',
    linkedInUrl: 'https://www.linkedin.com/in/stijnleijte/',
    phone: '+31 (0)6 1551 3911',
  },
  {
    name: 'Margo van de Rijt',
    email: 'm.vd.rijt@motuspeople.com',
    image: 'assets/images/margo-vd-rijt.png',
    description:
      'An optimist pure sang, with both feet on the ground, independent, and analytically-minded; just a few words that would characterise me. My aim is to use my extensive knowledge and years of rich experience within financial administration in a positive way, so that at the end of the day the bottom line results are correct. That’s what makes me happy everytime again!',
    isActive: true,
    role: 'Finance',
    linkedInUrl: 'https://www.linkedin.com/in/margo-van-de-rijt-7529b85a/',
    phone: '+31 (0)6 5397 5005',
  },
];
