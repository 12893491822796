<section class="landing-view h-full py-20 md:py-0 md:h-[75vh] relative">
  <div
    class="flex flex-wrap content-center h-full relative mx-auto max-w-screen-lg"
  >
    <div class="flex flex-col w-full mx-6 xl:mx-0">
      <div
        class="flex flex-wrap w-full h-full content-center justify-center sm:justify-start pr-1"
      >
        <div
          class="flex items-start md:items-end flex-col md:flex-row w-full pb-4"
        >
          <h1 class="main-title">{{ "HOME.MAIN_TITLE" | translate }},</h1>
          <h2 class="secondary-title">{{ "HOME.SUBTITLE" | translate }}</h2>
        </div>
      </div>
      <app-career-searcher [showViewAll]="true"></app-career-searcher>
    </div>
  </div>
  <div
    class="bg-logo-overlay w-full md:w-2/3 absolute top-0 bottom-0 left-0 h-full opacity-0 md:opacity-20 lg:opacity-30 xl:opacity-40 2xl:opacity-60"
  ></div>
  <div
    class="hidden md:block bg-gradient-to-tr from-primary-900 via-primary-500 to-secondary-800 opacity-20 md:opacity-90 bg-landing-view w-3/5 fixed -z-50 top-0 bottom-0 right-0 h-full"
  >
    <app-globe [hasTransparentBackground]="true"></app-globe>
  </div>
</section>

<div class="relative">
  <section class="relative latest-jobs py-12 md:py-24 bg-gray-100">
    <div
      class="dashed-line-secondary left-line absolute -top-14 left-4 md:left-40"
    ></div>

    <div
      class="container flex flex-col w-full items-start md:items-center justify-center"
    >
      <h2 class="text-3xl lg:text-4xl xl:text-5xl font-extrabold">
        {{ "HOME.LATEST_JOBS" | translate }}
      </h2>
      <h3 class="text-xl md:text-2xl text-gray-500">
        {{ "HOME.LATEST_JOBS_SUBTITLE" | translate }}
      </h3>
    </div>
    <div
      class="featured-jobs pt-4"
      [ngClass]="{ 'justify-center items-center': latestJobs.length < 4 }"
    >
      <drag-scroll #nav [snap-disabled]="true">
        <ng-container *ngFor="let job of latestJobs">
          <div
            class="select-none w-full sm:w-1/2 2xl:w-1/3 px-6 pb-8 pt-5 md:pb-12 md:pt-10"
            drag-scroll-item
          >
            <app-job-card [job]="job"></app-job-card>
          </div>
        </ng-container>
      </drag-scroll>
      <nz-empty
        class="p-10"
        *ngIf="latestJobs.length === 0"
        nzNotFoundContent="No jobs available"
      ></nz-empty>
    </div>
    <div>
      <div class="hidden md:flex w-full gap-2 justify-center py-4">
        <button
          nz-button
          nzType="default"
          [nzSize]="'large'"
          nzShape="round"
          (click)="nav.moveLeft()"
        >
          <i class="fa fa-angle-left"></i>
        </button>
        <button
          nz-button
          nzType="default"
          [nzSize]="'large'"
          nzShape="round"
          (click)="nav.moveRight()"
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>

    <div class="container flex w-full md:justify-center pt-4">
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        nzShape="round"
        [routerLink]="['jobs']"
      >
        Discover all careers
      </button>
    </div>
  </section>

  <section class="relative">
    <div class="md:flex w-full bg-white">
      <div class="md:flex-1"></div>
      <img
        class="block md:hidden w-full h-48 object-cover"
        src="assets/work_us-min.jpg"
        alt="Handshake"
      />
      <div class="md:flex-1">
        <div
          class="px-6 md:pl-0 md:pr-4 pt-6 pb-12 md:flex flex-wrap content-center w-full h-full max-w-lg relative"
        >
          <div class="w-full flex flex-col gap-6 pr-2 py-8 md:py-20">
            <h2 class="text-3xl lg:text-4xl font-extrabold">
              {{ "HOME.CONTENT.SLOGAN" | translate }}
            </h2>
            <div class="text-base md:text-lg font-light">
              <!-- <span class="text-2xl text-primary-500 font-semibold">
              {{ 'HOME.CONTENT.SLOGAN' | translate }}
            </span> -->

              <p class="pb-3">
                {{ "HOME.CONTENT.PAR_ONE" | translate }}
              </p>
              <!--            <p class="pb-3">-->
              <!--              {{ "HOME.CONTENT.PAR_TWO" | translate }}-->
              <!--            </p>-->
              <!--            <p class="pb-3">-->
              <!--              {{ "HOME.CONTENT.PAR_THREE" | translate }}-->
              <!--            </p>-->
            </div>
            <div class="md:flex md:text-center">
              <div class="pr-0 md:pr-4 pb-4 md:pb-0">
                <button
                  nz-button
                  nzType="primary"
                  [nzSize]="'large'"
                  nzShape="round"
                  [routerLink]="['about']"
                >
                  Learn more about Motus
                </button>
              </div>

              <button
                nz-button
                nzType="default"
                [routerLink]="['contact']"
                [nzSize]="'large'"
                nzShape="round"
              >
                Get in touch with us
              </button>
            </div>
          </div>
          <div
            class="dashed-line-secondary right-line absolute top-0 right-0"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="hidden md:block md:opacity-90 bg-work-us w-1/2 absolute top-0 left-0 h-full"
    ></div>
  </section>

  <section class="bg-white">
    <div class="container flex flex-col md:flex-row gap-8 py-16 md:py-32">
      <h3 class="text-3xl lg:text-4xl xl:text-5xl font-extrabold">
        Navigate directly
      </h3>
      <div
        class="w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 md:gap-16"
      >
        <a
          [routerLink]="['about']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
        >
          About
        </a>
        <a
          [routerLink]="['jobs']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >Careers</a
        >
        <a
          [routerLink]="['family']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >Family</a
        >
        <a
          [routerLink]="['employer']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >Work with us</a
        >
        <a
          href="https://www.linkedin.com/company/motus-people/"
          target="_blank"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >LinkedIn</a
        >
        <a
          href="https://www.instagram.com/motus_people/"
          target="_blank"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >Instagram</a
        >
        <a
          [routerLink]="['contact']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >Contact</a
        >
        <a
          [routerLink]="['news']"
          class="text-2xl py-2 text-primary-500 font-['Roboto'] font-bold border-b border-solid border-gray-200"
          >News</a
        >
      </div>
    </div>
  </section>

  <section class="bg-gray-100">
    <div
      class="flex flex-col items-start md:items-center container py-16 md:py-24"
    >
      <div
        class="flex flex-col w-full items-start md:items-center justify-center"
      >
        <h3 class="text-3xl lg:text-4xl xl:text-5xl font-extrabold">
          Stay Informed
        </h3>
        <div class="text-xl md:text-center md:text-2xl text-gray-500">
          Don't miss out on the latest updates
        </div>
      </div>

      <div class="py-12 grid grid-cols-1 md:grid-cols-3 gap-4">
        <ng-container *ngFor="let newsItem of latestNewsItems | slice : 0 : 3">
          <app-news-card [newsItem]="newsItem"></app-news-card>
        </ng-container>
        <ng-container *ngIf="latestNewsItems.length < 1">
          <h3 class="text-3xl text-primary-500">No articles yet, stay tuned</h3>
        </ng-container>
      </div>

      <button
        nz-button
        [routerLink]="['news']"
        nzType="primary"
        [nzSize]="'large'"
        nzShape="round"
      >
        See what's new
      </button>
    </div>
  </section>

  <section class="-mb-64 md:-mb-48">
    <div class="relative">
      <h2
        class="mix-blend-difference globe-title absolute z-50 top-0 left-0 right-0 text-gray-100 font-black pt-16 text-4xl md:text-5xl xl:text-6xl text-center"
      >
        Discover the Origins of Our Family
      </h2>
      <!--    <div #globeSection></div>-->
      <div class="h-[1000px]">
        <app-globe></app-globe>
      </div>
    </div>
  </section>

  <section class="bg-gradient-to-b from-black via-black via-35% to-primary-500">
    <app-cta-section></app-cta-section>
  </section>
</div>
