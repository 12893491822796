import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { allTeamMembers } from 'src/app/shared/variables';
import { ITeamMember } from '../../../../interfaces/team-member';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  protected readonly allTeamMembers = allTeamMembers;
  constructor(
    private translate: TranslateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.setTitle();
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Neem contact op';
    } else {
      title = 'Contact us';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

  membersToContact(): ITeamMember[] {
    return this.allTeamMembers.slice(0, 3);
  }
}
