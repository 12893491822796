import { ITeamMember } from '../interfaces/team-member';
import { IOfficePlace } from '../interfaces/office';

function createGoogleMapsIframe(url: string): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.width = '100%';
  iframe.height = '450';
  iframe.style.border = '0';
  iframe.allowFullscreen = true;
  iframe.style.borderRadius = '0.5rem';
  iframe.style.boxShadow =
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)';
  iframe.referrerPolicy = 'no-referrer-when-downgrade';
  (iframe as any).loading = 'lazy';
  return iframe;
}

export const officePlaces: IOfficePlace[] = [
  {
    place: 'Eindhoven',
    imageUrl: 'assets/office-outside-min.jpg',
    address: 'Insulindelaan 115',
    country: 'The Netherlands',
    email: 'info@motuspeople.com',
    phone: '+31 6 2362 2416',
    zipcode: '5642 CV',
    mapsUrl: createGoogleMapsIframe(
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.7237475846114!2d5.504101070833827!3d51.44172234818286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6d9294e5d9075%3A0x49b8bd319170c306!2sMotus%20People!5e0!3m2!1snl!2snl!4v1712344027641!5m2!1snl!2snl'
    ),
  },
  {
    place: 'Aveiro',
    imageUrl: 'assets/aveiro-office-min.jpg',
    address: 'Rua Cristóvão Pinho Queimado 33',
    country: 'Portugal',
    email: 'info@motuspeople.com',
    phone: '+31 6 2362 2416',
    zipcode: '3800-012',
    mapsUrl: createGoogleMapsIframe(
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.6760576795223!2d-8.638771323195952!3d40.63702547140496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd239875137b9841%3A0x6310cbaa24f0947c!2sR.%20Cristov%C3%A3o%20Pinho%20Queimado%2033%2C%203800-012%20Aveiro%2C%20Portugal!5e0!3m2!1snl!2snl!4v1722419089067!5m2!1snl!2snl'
    ),
  },
];

export const allTeamMembers: ITeamMember[] = [
  {
    name: 'Maarten Kleve',
    email: 'm.kleve@motuspeople.com',
    image: 'assets/images/maarten-kleve.png',
    description:
      "I'll do my utmost to further develop people from all over the world in their personal and professional careers! Connect them with the most advanced tech companies in the region and provide full relocation support.\n" +
      '\n' +
      'My job is simple; I want to work with great people.',
    isActive: true,
    role: 'Managing Director',
    linkedInUrl: 'https://www.linkedin.com/in/maarten-k-4a185430/',
    phone: '+31 (0)6 23622416',
  },
  {
    name: 'René Hooijmans',
    email: 'r.hooijmans@motuspeople.com',
    image: 'assets/images/rene-hooijmans.png',
    description:
      'Impacting careers and touching lives. That’s what drives me to give the very best of myself every single day.\n' +
      '\n' +
      'With my background in Human Resources, I would say I’m truly passionate about people development and career progress. I’m not the typical recruiter. For me, it’s all about enabling talents in identifying their perfect next step by finding a company and position in which they will be challenged and learn, thereby allowing them to take their development and careers to the next level. For me, knowing that I work with talented people who entrust me to change their careers and lives fills me with pride every day.',
    isActive: true,
    role: 'Director Engineering',
    linkedInUrl: 'https://www.linkedin.com/in/renehooijmans/',
    phone: '+31 (0)6 44551915',
  },
  {
    name: 'Stephan Smulders',
    email: 's.smulders@motuspeople.com',
    image: 'assets/images/stephan-smulders.png',
    description:
      'My entire career has been focussed on international recruitment for high-tech companies active in the brainport region. I consider myself an expert in supporting talents in their relocation to the Netherlands, where I give the best of myself in supporting my candidates in every aspect of the process. With my personal touch and genuine care for people, I try to touch people’s lives day by day. Leading the Operations business unit of Motus People, my speciality lies in recruitment for all Technician functions across all technical domains. Interested to know more? It would be my pleasure to connect in order to discuss our openings and to explain how I can help to change your life as from tomorrow.',
    isActive: true,
    role: 'Director Operations',
    linkedInUrl: 'https://www.linkedin.com/in/stephansmulders/',
    phone: '+31 (0)6 21434481',
  },
  {
    name: 'Natasha Slabbert',
    email: 's.slabbert@motuspeople.com',
    image: 'assets/images/natasha-slabbert.png',
    description:
      'My first job involved teaching and looking after little humans. (First Grade Teacher) In April 2023, my husband and I relocated to the Netherlands, from South Africa. In addition to this exciting new step, I also joined the Motus People family, where I am currently blooming.\n' +
      '\n' +
      'At the end of the day, I have always had a heart for people and as a Recruitment Consultant, it is my privilege to be able to help my candidates make the transition a smooth and pleasant experience.\n' +
      '\n' +
      'Beyond my profession, you’ll also find a person who enjoys DIY’s, good food, dancing and loves her dog unconditionally. \n' +
      '\n' +
      '“The only way to make sense out of change, is to plunge into it, move with it, and join the dance.” – Alan W. Watts',
    isActive: true,
    role: 'Recruitment Consultant',
    linkedInUrl:
      'https://www.linkedin.com/in/natasha-slabbert-helpingpeoplegrow/',
    phone: '+31 (0)6 8127 6670',
  },
  {
    name: 'Stijn Leijte',
    email: 's.leijte@motuspeople.com',
    image: 'assets/images/stijn-leijte.png',
    description:
      'As a previous technician myself, I believe I can really add value to the lives and careers of both employee and employer in the high-tech industry.\n' +
      '\n' +
      'I am always up for a chat to discuss your career prospects. Let’s build your future together!',
    isActive: true,
    role: 'Recruitment Consultant',
    linkedInUrl: 'https://www.linkedin.com/in/stijnleijte/',
    phone: '+31 (0)6 1551 3911',
  },
  {
    name: 'Margo van de Rijt',
    email: 'm.vd.rijt@motuspeople.com',
    image: 'assets/images/margo-vd-rijt.png',
    description:
      'An optimist pure sang, with both feet on the ground, independent, and analytically-minded; just a few words that would characterise me. My aim is to use my extensive knowledge and years of rich experience within financial administration in a positive way, so that at the end of the day the bottom line results are correct. That’s what makes me happy everytime again!',
    isActive: true,
    role: 'Finance',
    linkedInUrl: 'https://www.linkedin.com/in/margo-van-de-rijt-7529b85a/',
    phone: '+31 (0)6 5397 5005',
  },
  {
    name: 'Sunantha Bester',
    email: 's.bester@motuspeople.com',
    description:
      'I am originally from South Africa, I am deeply committed to helping individuals find the perfect career opportunities. With a background in Sport Science and experience running my own business, my passion for working with people has always been at the forefront of my journey.\n' +
      '\n' +
      'In 2023, my husband and I moved to the Netherlands, where I now work in recruitment, guiding professionals through career transitions. As a recruiter at Motus People, I recognize the challenges of relocating and starting fresh, and I strive to make these transitions as seamless as possible, with a focus on meaningful and successful connections.',
    isActive: true,
    role: 'Recruiter',
    linkedInUrl: 'https://nl.linkedin.com/in/sunantha-bester/',
  },
];
