<div
  class="w-auto md:w-full flex flex-col relative items-start md:items-center rounded-3xl p-6 xl:p-8 shadow-2xl"
  [ngClass]="
    hasSecondaryColor
      ? 'bg-gradient-to-tr md:bg-gradient-to-r from-secondary-500 to-red-700'
      : 'bg-white'
  "
>
  <div class="flex flex-col justify-start md:flex-row w-full gap-4">
    <nz-input-group nzSize="large" [nzPrefix]="suffixIconSearch">
      <input
        [(ngModel)]="searchFilter"
        size="large"
        class=""
        type="text"
        nz-input
        placeholder="Search for your next career..."
        (keyup.enter)="
          notifyParent.emit();
          filterSearchService.applyFilters(
            {
              regions: regionFilter ? [regionFilter] : [],
              fields: expertiseFilter ? [expertiseFilter] : []
            },
            searchFilter
          )
        "
      />
    </nz-input-group>
    <nz-input-group class="w-full md:w-fit" nzSize="large">
      <nz-select
        class="w-full"
        [(ngModel)]="expertiseFilter"
        [nzSuffixIcon]="suffixIconJob"
        nzSize="large"
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Field of Expertise"
      >
        <nz-option
          *ngFor="let expertise of expertises"
          [nzLabel]="expertise.name.en"
          [nzValue]="expertise.slug"
        ></nz-option>
      </nz-select>
    </nz-input-group>
    <nz-input-group class="w-full md:w-fit" nzSize="large">
      <nz-select
        class="w-full"
        [(ngModel)]="regionFilter"
        [nzSuffixIcon]="suffixIconMap"
        nzSize="large"
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Region / City"
      >
        <nz-option
          *ngFor="let region of regions"
          [nzLabel]="region.name.en"
          [nzValue]="region.slug"
        ></nz-option>
      </nz-select>
    </nz-input-group>
    <button
      nz-button
      (click)="
        emitNavigate.emit();
        notifyParent.emit();
        filterSearchService.applyFilters(
          {
            regions: regionFilter ? [regionFilter] : [],
            fields: expertiseFilter ? [expertiseFilter] : []
          },
          searchFilter
        )
      "
      [nzType]="hasSecondaryColor ? 'default' : 'primary'"
      [nzSize]="'large'"
      nzShape="round"
    >
      Find your career
    </button>
  </div>

  <a
    *ngIf="showViewAll"
    (click)="emitNavigate.emit()"
    [routerLink]="['jobs']"
    class="w-fit flex gap-2 pt-6 items-center justify-center text-2xl"
    [ngClass]="
      hasSecondaryColor
        ? 'text-white hover:text-gray-200'
        : 'text-primary-500 hover:text-primary-300'
    "
    >View all careers <i class="cursor-pointer" nz-icon nzType="right"></i
  ></a>
</div>

<ng-template #suffixIconSearch>
  <i class="cursor-pointer" nz-icon nzType="search"></i>
</ng-template>

<ng-template #suffixIconMap>
  <i class="cursor-pointer" nz-icon nzType="environment"></i>
</ng-template>
<ng-template #suffixIconJob>
  <i class="cursor-pointer" nz-icon nzType="apartment"></i>
</ng-template>
