import { Component, OnInit } from '@angular/core';
import { allTeamMembers } from 'src/app/shared/variables';

@Component({
  selector: 'app-cta-section',
  templateUrl: './cta-section.component.html',
  styleUrls: ['./cta-section.component.scss'],
})
export class CtaSectionComponent implements OnInit {
  protected readonly allTeamMembers = allTeamMembers;
  generatedNumber = 0;

  ngOnInit(): void {
    this.generatedNumber = this.generateRandomNumber();
  }

  generateRandomNumber(): number {
    // TODO remove -1 when new employee has image and phone
    return Math.floor(Math.random() * (this.allTeamMembers.length - 1));
  }
}
