<section
  class="bg-gradient-to-tr from-primary-400 to-primary-700 flex items-center relative py-16 md:py-24 overflow-hidden z-10"
>
  <div class="container">
    <div class="h-full absolute top-0 left-0 z-0">
      <img
        src="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
        alt="background-logo"
        class="w-full h-full object-cover opacity-5"
      />
    </div>

    <div class="relative z-10 lg:my-8">
      <!--     <div #introText>-->
      <!--        <h1 class="intro-title">Your New Opportunity</h1>-->
      <!--        <h2 class="second-title">Explore Our Current Opportunities</h2>-->
      <!--      </div>-->
      <h1
        class="text-white text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold"
      >
        Get in touch with Motus
      </h1>
      <p class="text-gray-200 text-xl mt-2 max-w-xl">
        Don't hesitate to contact us for tailored solutions and dedicated
        support. We're here to assist you every step of the way.
      </p>
    </div>
  </div>
</section>

<section class="relative">
  <div class="md:flex w-full">
    <div class="md:flex-1"></div>
    <img
      class="block md:hidden w-full h-48 object-cover"
      src="assets/handshake-min.jpg"
      alt="Handshake"
    />
    <div class="md:flex-1">
      <div
        class="px-6 md:pt-8 xl:pt-16 pb-12 md:flex flex-wrap justify-center items-center content-center w-full h-full max-w-lg relative"
      >
        <div class="w-full py-12">
          <h2
            class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold pb-8"
          >
            {{ "CONTACT_PAGE.SUBTITLE" | translate }}
          </h2>
          <app-contact-form></app-contact-form>
        </div>
        <div
          class="dashed-line-secondary right-line absolute top-0 right-0"
        ></div>
      </div>
    </div>
  </div>
  <div
    class="hidden md:block opacity-5 md:opacity-90 bg-work-us w-1/2 absolute top-0 left-0 h-full"
  ></div>
</section>

<section>
  <div class="container py-12 md:py-32 flex flex-col md:flex-row gap-12">
    <div class="flex flex-1 flex-col gap-4 text-xl">
      <h2
        class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold mb-4"
      >
        Visit our office in Eindhoven
      </h2>
      <div>Insulindelaan 115</div>
      <div>5642 CV</div>
      <div>Eindhoven</div>
      <div>The Netherlands</div>
      <div><a href="mailto:info@motuspeople.com">info@motuspeople.com</a></div>
      <div><a href="tel:+31623622416"> +31 6 2362 2416 </a></div>
      <div class="mt-12">
        <iframe
          class="rounded-lg shadow-xl w-full h-64 md:h-96"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.7237475846114!2d5.504101070833827!3d51.44172234818286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6d9294e5d9075%3A0x49b8bd319170c306!2sMotus%20People!5e0!3m2!1snl!2snl!4v1712344027641!5m2!1snl!2snl"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="flex flex-1 h-full">
      <img
        class="rounded-lg shadow-xl flex-1 w-full h-full object-contain"
        src="assets/office-outside.png"
        alt="Our office from the outside"
      />
    </div>
  </div>
</section>

<section class="bg-gradient-to-tr from-secondary-500 to-red-700">
  <div
    class="container flex flex-col w-full items-start md:items-center py-16 md:py-32"
  >
    <h2
      class="text-white text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold mb-4"
    >
      Contact us directly
    </h2>
    <div
      class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pt-8 gap-6 md:gap-12"
    >
      <div
        *ngFor="let member of membersToContact()"
        class="bg-white flex gap-4 rounded-lg shadow-lg w-full p-4 md:p-6"
      >
        <img
          class="bg-black object-cover object-top rounded-full h-20 w-20"
          src="{{ member.image }}"
          alt="{{ member.name }}"
        />
        <div class="flex flex-col gap-2 md:gap-4">
          <div>
            <h3 class="text-lg font-semibold mb-0">{{ member.name }}</h3>
            <div>{{ member.role }}</div>
          </div>
          <div>
            <div>
              <a href="mailto:{{ member.email }}">
                <i
                  class="fa fa-envelope pr-2 text-secondary-500"
                  aria-hidden="true"
                ></i>
                {{ member.email }}
              </a>
            </div>
            <div class="pt-2">
              <a href="tel:{{ member.phone }}">
                <i
                  class="fa fa-phone pr-2 text-secondary-500"
                  aria-hidden="true"
                ></i>
                {{ member.phone }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
